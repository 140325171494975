import React, { FunctionComponent, useMemo } from 'react'
import { Box, List, ListItem, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { selectAppTableOfContent } from '../../app/selectors/docs'
import ExpandableDocumentationGroup from '../../components/ExpandableDocumentationGroup'
import { Link } from 'react-router-dom'

interface MenuItem {
  path: string
  route: string
  name: string
  metadata: Metadata
}

interface Group {
  name: string
  items: Array<MenuItem>
}

interface Metadata {
  order: number
  group: string
  title: string
}

const AppTableOfContent: FunctionComponent = () => {
  const { items, metadata } = useSelector(selectAppTableOfContent)
  const { groups } = metadata

  const groupedItems = useMemo(() => {
    if (!groups || groups.length === 0) return []
    return groups.map((groupName: string) => {
      const itemsInGroup = items
        .filter((item: MenuItem) => item.metadata.group === groupName)
        .sort((a: MenuItem, b: MenuItem) => a.metadata.order - b.metadata.order)

      return {
        name: groupName,
        items: itemsInGroup,
      } as Group
    })
  }, [groups, items]) as Array<Group>

  return (
    <>
      {groupedItems?.map((group: Group) => (
        <Box key={group.name} marginBottom={1}>
          <ExpandableDocumentationGroup name={group.name} noOfItems={group.items.length || 0}>
            {group.items && (
              <List dense>
                {group.items.map((item, index) => (
                  <ListItem key={index} button component={Link} to={item.route} divider={index !== group.items.length - 1}>
                    <Typography>{item.metadata.title || item.name}</Typography>
                  </ListItem>
                ))}
              </List>
            )}
          </ExpandableDocumentationGroup>
        </Box>
      ))}
    </>
  )
}

export default AppTableOfContent
