import { createMuiTheme } from '@material-ui/core/styles'

// The theme object can be used to configure the theme.
const Theme = createMuiTheme({
  palette: {
    primary: {
      light: '#35ae6a',
      main: '#009a44',
      dark: '#026b30',
    },
    background: {
      default: '#FFF',
      paper: '#FFF',
    },
  },
})

export default Theme
