import React, { FunctionComponent } from 'react'
import Markdown from 'markdown-to-jsx'
import { Box } from '@material-ui/core'
import MarkdownRouterLink from './MarkdownRouterLink'
import MarkdownImage from './MarkdownImage'
import { StorageSettings } from '../models/StorageSettings'

interface MarkdownViewerProps {
  content: string
  context: string
  storageSettings: StorageSettings
}

const MarkdownViewer: FunctionComponent<MarkdownViewerProps> = ({ content, context, storageSettings }) => {
  return (
    <Box m={2}>
      <Markdown
        options={{
          overrides: {
            a: {
              component: MarkdownRouterLink,
              props: {
                context: context,
              },
            },
            img: {
              component: MarkdownImage,
              props: {
                context: context,
                storageSettings: storageSettings,
              },
            },
          },
        }}
      >
        {content}
      </Markdown>
    </Box>
  )
}

export default MarkdownViewer
