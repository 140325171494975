import { Box, Button, CircularProgress, Dialog, Grid, Typography } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { login } from './app/actions/app/auth'
import { selectUserLoggedIn, selectUserLoginFailed } from './app/selectors/user'
import AppRouter from './AppRouter'
import UpdateBar from './containers/UpdateBar'
import Theme from './Theme'

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <AuthenticatingDialog />
      <AppRouter />
      <UpdateBar />
    </ThemeProvider>
  )
}

const AuthenticatingDialog = () => {
  const dispatch = useDispatch()
  const loggedIn = useSelector(selectUserLoggedIn)
  const loginFailed = useSelector(selectUserLoginFailed)
  const onRetry = () => dispatch(login())

  return (
    <Dialog open={!loggedIn}>
      <Grid container alignItems="center">
        <Grid item>
          <Box padding={2}>
            <CircularProgress />
          </Box>
        </Grid>
        <Grid item>
          <Box padding={2}>{loginFailed ? <Button onClick={onRetry}>Retry</Button> : <Typography>Authenticating</Typography>}</Box>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default App
