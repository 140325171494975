import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = ''

export const slice = createSlice({
  name: 'context',
  initialState,
  reducers: {
    setDocsContext(state, action: PayloadAction<string>) {
      if (action.payload.startsWith('/') || action.payload.startsWith('\\')) {
        return action.payload.substring(1, action.payload.length)
      }

      return action.payload
    },
  },
})

export const { setDocsContext } = slice.actions

export default slice.reducer
