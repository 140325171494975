import { RootState } from '../store'

const selectApiEndpoint = (state: RootState) => state.app.settings.api_endpoint
const selectAppVersion = (state: RootState) => state.app.settings.app_version
const selectUserAuthority = (state: RootState) => state.app.settings.authority
const selectStorageSettings = (state: RootState) => state.app.storageSettings
const selectServiceWorkerInitialized = (state: RootState) => state.app.serviceWorker.initialized
const selectServiceWorkerUpdated = (state: RootState) => state.app.serviceWorker.updated
const selectServiceWorkerInstance = (state: RootState) => state.app.serviceWorker.instance

export {
  selectUserAuthority,
  selectApiEndpoint,
  selectAppVersion,
  selectStorageSettings,
  selectServiceWorkerInitialized,
  selectServiceWorkerUpdated,
  selectServiceWorkerInstance,
}
