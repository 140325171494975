import { Dispatch, Middleware } from '@reduxjs/toolkit'
import { User } from 'oidc-client'
import { login, logout } from '../../app/actions/app/auth'
import { initializeApp } from '../../app/actions/app/settings'
import { setUserData } from '../../app/actions/user/data'
import { setUserLoggedIn, setUserLoginFailed } from '../../app/actions/user/loggedIn'
import { Settings } from '../../models/Settings'
import { UserData } from '../../models/UserData'
import { AuthService } from './AuthService'

function dispatchSetUserdata(dispatch: Dispatch, user: User) {
  dispatch(
    setUserData({
      accessToken: user.access_token,
      email: user.profile.email ?? ``,
      name: user.profile.name ?? ``,
      tenantName: user.profile.tenant_displayname ?? ``,
      roles: [user.profile.role]?.flat() ?? [],
    })
  )
}

function createAuthService(dispatch: Dispatch, settings: Settings): AuthService {
  const authService = new AuthService(settings)

  authService.userManager.events.addUserLoaded((user: User) => {
    dispatch(setUserLoggedIn(true))
    dispatchSetUserdata(dispatch, user)
  })

  authService.userManager.events.addUserSignedOut(() => {
    dispatch(setUserLoggedIn(false))
    dispatch(login()) // trigger login
  })

  authService.userManager.events.addAccessTokenExpired(() => {
    dispatch(setUserLoggedIn(false))
    dispatch(login()) // trigger login
  })

  return authService
}

const oidcSyncMiddleware: Middleware = ({ dispatch, getState }) => {
  let authService: AuthService
  let fetchLogin: ReturnType<typeof makeFetchLogin>
  return (next) => (action) => {
    if (initializeApp.match(action)) {
      authService = createAuthService(dispatch, action.payload)
      fetchLogin = makeFetchLogin(action.payload.api_endpoint ?? window.location.origin)
    }
    if (initializeApp.match(action) || login.match(action)) {
      const storedSecret = getStoredSharedSecret()
      const { secret = storedSecret, ...remainder } = getLocationHashObject() ?? {}
      if (secret) {
        fetchLogin(secret).then(
          (user) => {
            setStoredSharedSecret(secret)
            setLocationHashObject(remainder)
            dispatch(setUserLoggedIn(true))
            dispatch(setUserData(user))
          },
          () => {
            dispatch(setUserLoginFailed(true))
          }
        )
      } else {
        authService.getUser().then(
          (user) => {
            const isLoggedIn = user && !user.expired
            if (isLoggedIn) {
              dispatch(setUserLoggedIn(true))
              dispatchSetUserdata(dispatch, user!)
            } else {
              authService.login()
            }
          },
          () => {
            dispatch(setUserLoginFailed(true))
          }
        )
      }
    }
    if (logout.match(action)) {
      authService.logout()
    }
    next(action)
  }
}

// (de)serialize location.hash value
const getLocationHashObject = (): Record<string, string> => {
  const hash = window.location.hash.substring(1)
  const parts = hash.split(`&`)
  return Object.fromEntries(parts.map((x) => x.split(`=`).map((kv) => decodeURIComponent(kv))))
}
const setLocationHashObject = (args: Record<string, string>) => {
  const serialize = Object.entries(args)
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .join(`&`)
  window.location.hash = serialize ? `#${serialize}` : ``
}

// remember previous successful secret
const getStoredSharedSecret = () => sessionStorage.getItem(`sharedsecret`)
const setStoredSharedSecret = (value: string) => sessionStorage.setItem(`sharedsecret`, value)

// json api request for token to UserData
const makeFetchLogin = (api_endpoint: string) => (secret: string): Promise<UserData> => {
  const body = { secret }
  return fetch(`${api_endpoint}/authenticate/login`, { method: `POST`, headers: { 'content-type': `application/json` }, body: JSON.stringify(body) })
    .then((x) => x.json())
    .then((user) => ({ accessToken: user.token, email: ``, name: ``, tenantName: ``, roles: [] }))
}

export default oidcSyncMiddleware
