import { combineReducers } from '@reduxjs/toolkit'
import context from './context'
import settings from './settings'
import storageSettings from './storageSettings'
import serviceWorker from './serviceWorker'

export default combineReducers({
  context,
  settings,
  storageSettings,
  serviceWorker,
})
