import { combineReducers } from '@reduxjs/toolkit'
import tableOfContent from './tableOfContent'
import markdown from './markdown'
import routes from './routes'
import context from './context'

export default combineReducers({
  tableOfContent,
  markdown,
  routes,
  context,
})
