import React, { useEffect } from 'react'
import NotFound from '../../components/NotFound'
import MarkdownViewer from '../../components/MarkdownViewer'
import Loader from '../../components/Loader'
import { useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMarkdownDocumentByRoute } from '../../app/actions/docs/fetchers'
import { selectMarkdownLoading, selectMarkdownContent, selectRoutes, selectContext } from '../../app/selectors/docs'
import { Grid } from '@material-ui/core'
import { selectStorageSettings } from '../../app/selectors/app'

const isKnownRoute = (routes: Array<string>, route: string) => {
  let routeToCheck = route.replace(/\//g, `\\`)

  if (routeToCheck.startsWith('\\')) {
    routeToCheck = routeToCheck.substring(1, routeToCheck.length)
  }

  return routes.includes(routeToCheck)
}

const removeFirstSlash = (route: string) => {
  if (route.startsWith('/')) {
    return route.substring(1, route.length)
  }

  return route
}

const DynamicRouteHandler = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const loading = useSelector(selectMarkdownLoading)
  const content = useSelector(selectMarkdownContent)
  const routes = useSelector(selectRoutes)
  const context = useSelector(selectContext)
  const storageSettings = useSelector(selectStorageSettings)

  const knownRoute = isKnownRoute(routes, pathname)
  const pathName = removeFirstSlash(pathname)

  useEffect(() => {
    dispatch(fetchMarkdownDocumentByRoute(pathName))
  }, [pathName])

  return (
    <>
      {knownRoute ? (
        loading ? (
          <Grid container justify="center">
            <Loader />
          </Grid>
        ) : (
          <MarkdownViewer content={content} context={context} storageSettings={storageSettings} />
        )
      ) : (
        <NotFound />
      )}
    </>
  )
}

export default DynamicRouteHandler
