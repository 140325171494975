import React from 'react'
import { Box, Typography } from '@material-ui/core'

const NotFound = () => {
  return (
    <Box>
      <Typography variant="h5">Not Found</Typography>
    </Box>
  )
}

export default NotFound
