import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface MarkdownState {
  content: string
  loading: boolean
  error: string | null
}

const initialState: MarkdownState = {
  content: '',
  loading: false,
  error: null,
}

export const slice = createSlice({
  name: 'markdown',
  initialState,
  reducers: {
    getMarkdownContentStart(state) {
      state.loading = true
      state.error = null
    },
    getMarkdownContentSuccess(state, action: PayloadAction<any>) {
      state.content = action.payload ? action.payload : initialState.content
      state.loading = false
      state.error = null
    },
    getMarkdownContentFailure(state, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const { getMarkdownContentStart, getMarkdownContentSuccess, getMarkdownContentFailure } = slice.actions

export default slice.reducer
