import { RootState } from '../store'
import { createSelector } from '@reduxjs/toolkit'

const selectTableOfContent = (state: RootState) => state.docs.tableOfContent.data
const selectRoutes = (state: RootState) => state.docs.routes.data
const selectMarkdownContent = (state: RootState) => state.docs.markdown.content
const selectMarkdownLoading = (state: RootState) => state.docs.markdown.loading
const selectContext = (state: RootState) => state.docs.context

const selectAppTableOfContent = (state: RootState) => selectAppTableOfContentRS(state)
const selectAppTableOfContentRS = createSelector([selectTableOfContent, selectContext], (tableOfContent, context) => {
  const toc = tableOfContent.find((item: any) => item.name === context) as any

  if (toc && toc.items) {
    const files = toc.items.filter((item: any) => item.type === 'file')

    return {
      ...toc,
      items: files,
    }
  }

  return {
    items: [],
    metadata: {
      groups: [],
    },
  }
})

export { selectTableOfContent, selectAppTableOfContent, selectRoutes, selectMarkdownContent, selectMarkdownLoading, selectContext }
