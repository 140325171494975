import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface TableOfContentState {
  data: Array<object>
  loading: boolean
  error: string | null
}

const initialState: TableOfContentState = {
  data: [],
  loading: false,
  error: null,
}

export const slice = createSlice({
  name: 'tableOfContent',
  initialState,
  reducers: {
    getTableOfContentStart(state) {
      state.loading = true
      state.error = null
    },
    getTableOfContentSuccess(state, action: PayloadAction<any>) {
      state.data = action.payload ? action.payload : initialState.data
      state.loading = false
      state.error = null
    },
    getTableOfContentFailure(state, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const { getTableOfContentStart, getTableOfContentSuccess, getTableOfContentFailure } = slice.actions

export default slice.reducer
