import { BlobServiceClient, ContainerClient } from '@azure/storage-blob'
import { StorageSettings } from '../models/StorageSettings'

export class BlobStorageClient {
  private static instance: BlobStorageClient
  private containerClient: ContainerClient
  private settings: StorageSettings

  private constructor(settings: StorageSettings) {
    const blobServiceClient = new BlobServiceClient(`${settings.blobStorageUri}?${settings.sasToken}`)
    this.settings = settings
    this.containerClient = blobServiceClient.getContainerClient(settings.containerName)
  }

  public static getInstance(settings: StorageSettings): BlobStorageClient {
    if (!BlobStorageClient.instance || BlobStorageClient.instance.settings.sasToken !== settings.sasToken) {
      BlobStorageClient.instance = new BlobStorageClient(settings)
    }

    return BlobStorageClient.instance
  }

  public async getTableOfContent(resolve: Function, reject: Function) {
    const blobClient = this.containerClient.getBlobClient(`${this.settings.contentFolder}\\toc.json`)
    const blob = await blobClient.download()
    const blobBody = (await blob.blobBody) || new Blob()

    readBlob(blobBody, resolve, reject)
  }

  public async getRoutes(resolve: Function, reject: Function) {
    const blobClient = this.containerClient.getBlobClient(`${this.settings.contentFolder}\\routes.json`)
    const blob = await blobClient.download()
    const blobBody = (await blob.blobBody) || new Blob()

    readBlob(blobBody, resolve, reject)
  }

  public async getMarkdownDocument(path: string, resolve: Function, reject: Function) {
    const blobClient = this.containerClient.getBlobClient(`${this.settings!.contentFolder}\\${path}`)
    const blob = await blobClient.download()
    const blobBody = (await blob.blobBody) || new Blob()

    readBlob(blobBody, resolve, reject)
  }
}

const readBlob = (blob: Blob, resolve: Function, reject: Function) => {
  const reader = new FileReader()
  reader.onload = function () {
    resolve(reader.result)
  }
  reader.onerror = function () {
    reject(reader.error?.message)
  }

  reader.readAsText(blob)
}
