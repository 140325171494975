import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { persistStateReducer } from '../../persistence'

export const slice = createSlice({
  name: 'context',
  initialState: {
    companyId: ``,
  },
  reducers: {
    setCompany: (state, action: PayloadAction<string>) => {
      state.companyId = action.payload
    },
  },
})

export const { setCompany } = slice.actions

export default persistStateReducer(`app-context`, slice.reducer)
