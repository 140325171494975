import { createSlice } from '@reduxjs/toolkit'

// The reducers below are not connected to the state.
// The actions are only used to trigger the auth flow through the Redux middleware.
const initialState = null

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: () => {},
    logout: () => {}
  }
})

export const { login, logout } = slice.actions
