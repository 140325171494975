import { Card, CardHeader, Collapse, IconButton, makeStyles } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined'
import ImportContactsIcon from '@material-ui/icons/ImportContactsOutlined'
import clsx from 'clsx'
import React, { useState, FunctionComponent } from 'react'

interface props {
  name: string
  noOfItems: number
}

const ExpandableDocumentationGroup: FunctionComponent<props> = ({ name, noOfItems, children }) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  const onToggle: React.MouseEventHandler = (e) => {
    e.stopPropagation()
    setExpanded((x) => !x)
  }

  return (
    <Card>
      <CardHeader
        className={classes.header}
        avatar={<ImportContactsIcon />}
        title={name}
        subheader={`${noOfItems} items`}
        action={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={onToggle}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        }
        onClick={onToggle}
      />
      <Collapse in={expanded} unmountOnExit>
        {children}
      </Collapse>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  header: {
    position: `sticky`,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}))

export default ExpandableDocumentationGroup
