import { AppThunk, store } from '../../store'
import { getTableOfContentStart, getTableOfContentFailure, getTableOfContentSuccess } from './tableOfContent'
import { selectStorageSettings } from '../../selectors/app'
import { getMarkdownContentStart, getMarkdownContentSuccess, getMarkdownContentFailure } from './markdown'
import { getRoutesStart, getRoutesSuccess, getRoutesFailure } from './routes'
import { BlobStorageClient } from '../../../lib/blobStorageClient'

export const fetchTableOfContent = (): AppThunk => async (dispatch) => {
  dispatch(getTableOfContentStart())

  const storageSettings = selectStorageSettings(store.getState())

  if (!storageSettings.sasToken) return

  try {
    const client = BlobStorageClient.getInstance(storageSettings)

    const resolve = (content: string) => {
      const json = JSON.parse(content)
      dispatch(getTableOfContentSuccess(json))
    }
    const reject = (error: string) => {
      dispatch(getTableOfContentFailure(error))
    }

    await client.getTableOfContent(resolve, reject)
  } catch (error) {
    dispatch(getTableOfContentFailure(error))
  }
}

export const fetchMarkdownDocumentByRoute = (route: string): AppThunk => async (dispatch) => {
  dispatch(getMarkdownContentStart())

  const storageSettings = selectStorageSettings(store.getState())

  if (!storageSettings.sasToken) return

  try {
    const client = BlobStorageClient.getInstance(storageSettings)

    const resolve = (content: string) => {
      dispatch(getMarkdownContentSuccess(content))
    }
    const reject = (error: string) => {
      dispatch(getMarkdownContentFailure(error))
    }

    await client.getMarkdownDocument(`${route}.md`, resolve, reject)
  } catch (error) {
    dispatch(getMarkdownContentFailure(error))
  }
}

export const fetchRoutes = (): AppThunk => async (dispatch) => {
  dispatch(getRoutesStart())

  const storageSettings = selectStorageSettings(store.getState())

  if (!storageSettings.sasToken) return

  try {
    const client = BlobStorageClient.getInstance(storageSettings)

    const resolve = (content: string) => {
      const json = JSON.parse(content)
      dispatch(getRoutesSuccess(json))
    }
    const reject = (error: string) => {
      dispatch(getRoutesFailure(error))
    }

    await client.getRoutes(resolve, reject)
  } catch (error) {
    dispatch(getRoutesFailure(error))
  }
}
