import React from 'react'
import AppDrawer from './containers/AppDrawer'
import { Box } from '@material-ui/core'
import Navigation from './components/Navigation'

const Main = ({ children }: { children: React.ReactNode }) => {
  return (
    <AppDrawer>
      <Box m={1}>
        <Navigation>{children}</Navigation>
      </Box>
    </AppDrawer>
  )
}

export default Main
