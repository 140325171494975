import React from 'react'
import { useSelector } from 'react-redux'
import { selectServiceWorkerUpdated, selectServiceWorkerInstance } from '../../app/selectors/app'
import { Snackbar, Typography } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

const UpdateBar = () => {
  const swUpdated = useSelector(selectServiceWorkerUpdated)
  const swInstance = useSelector(selectServiceWorkerInstance)

  const updateServiceWorker = () => {
    const swRegistration = swInstance?.waiting

    if (!swRegistration) return

    swRegistration.postMessage({ type: 'SKIP_WAITING' })
    swRegistration.addEventListener('statechange', (e) => {
      const target = e.target as ServiceWorker
      if (target.state === 'activated') {
        window.location.reload()
      }
    })
  }

  return (
    <>
      {swUpdated && (
        <Snackbar open={true}>
          <MuiAlert elevation={6} variant="filled" severity="info" onClick={updateServiceWorker}>
            <Typography>Click to Update</Typography>
          </MuiAlert>
        </Snackbar>
      )}
    </>
  )
}

export default UpdateBar
