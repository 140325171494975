import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface RoutesState {
  data: Array<string>
  loading: boolean
  error: string | null
}

const initialState: RoutesState = {
  data: [],
  loading: false,
  error: null,
}

export const slice = createSlice({
  name: 'routes',
  initialState,
  reducers: {
    getRoutesStart(state) {
      state.loading = true
      state.error = null
    },
    getRoutesSuccess(state, action: PayloadAction<any>) {
      state.data = action.payload ? action.payload : initialState.data
      state.loading = false
      state.error = null
    },
    getRoutesFailure(state, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const { getRoutesStart, getRoutesSuccess, getRoutesFailure } = slice.actions

export default slice.reducer
