import React, { FunctionComponent, useEffect } from 'react'
import { Typography, Box } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { selectStorageSettings } from '../../app/selectors/app'
import { fetchTableOfContent, fetchRoutes } from '../../app/actions/docs/fetchers'
import AppTableOfContent from './AppTableOfContent'
import { setDocsContext } from '../../app/actions/docs/context'
import { useLocation } from 'react-router-dom'

interface AppLandingProps {
  appFriendlyName: string
}

const AppLanding: FunctionComponent<AppLandingProps> = ({ appFriendlyName }) => {
  const dispatch = useDispatch()
  const storageSettings = useSelector(selectStorageSettings)
  const { pathname } = useLocation()

  useEffect(() => {
    dispatch(setDocsContext(pathname))
  }, [pathname, dispatch])

  useEffect(() => {
    dispatch(fetchTableOfContent())
    dispatch(fetchRoutes())
  }, [storageSettings, dispatch])

  return (
    <Box>
      <Box ml={2} mt={2} mb={1}>
        <Typography variant="h6">{appFriendlyName}</Typography>
      </Box>
      <AppTableOfContent />
    </Box>
  )
}

export default AppLanding
