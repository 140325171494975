import React, { FunctionComponent, useMemo } from 'react'
import { StorageSettings } from '../models/StorageSettings'
import { createFileSASUri } from '../lib/blobStorageFunctions'

interface MarkdownImageProps {
  src: string
  context: string
  storageSettings: StorageSettings
}

const MarkdownImage: FunctionComponent<MarkdownImageProps> = ({ context, src, storageSettings }) => {
  const sourceLink = useMemo(() => {
    return createFileSASUri(storageSettings, context, src)
  }, [context, src, storageSettings])

  return (
    <>
      <img src={sourceLink} alt="" style={{ width: '100%' }} />
    </>
  )
}

export default MarkdownImage
