import { Box, ListItem, ListItemText, ListItemAvatar, Avatar } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import MenuIcon from '@material-ui/icons/Menu'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectAppVersion } from '../../app/selectors/app'
import { logout } from '../../app/actions/app/auth'
import logo from './logo-normal.svg'
import { Link } from 'react-router-dom'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  headerLink: {
    textDecoration: 'none',
  },
}))

interface Props {
  children?: any
}

const AppDrawer = (props: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const appVersion = useSelector(selectAppVersion)
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <Box height="100%" flexDirection="column" display="flex">
      <Box flex={1}>
        <List>
          <Toolbar>
            <img alt="Mprise Agriware Logo" src={logo} style={{ maxWidth: `100%` }} />
          </Toolbar>
          <Divider />
        </List>
      </Box>
      <Box>
        <List>
          <ListItem button onClick={() => dispatch(logout())}>
            <ListItemAvatar>
              <Avatar>
                <ExitToAppOutlinedIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Logout" />
          </ListItem>
          <ListItem dense>
            <ListItemText>
              <Typography variant="caption" color="textSecondary">
                v{appVersion ?? `0.0.0`}
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Box>
    </Box>
  )

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar>
          <Box flexBasis={48 + 16}>
            <IconButton aria-label="open drawer" edge="start" onClick={handleDrawerToggle} className={classes.menuButton}>
              <MenuIcon />
            </IconButton>
          </Box>
          <ListItem button component={Link} to={'/'}>
            <Typography variant="h6">Agriware app docs</Typography>
          </ListItem>
        </Toolbar>
      </AppBar>
      <nav aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  )
}

export default AppDrawer
