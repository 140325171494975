import { CircularProgress } from '@material-ui/core'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/lib/integration/react'
import App from './App'
import { serviceWorkerInitialized, serviceWorkerUpdated } from './app/actions/app/serviceWorker'
import { initializeApp } from './app/actions/app/settings'
import { persistor, store } from './app/store'
import './index.css'
import { Settings } from './models/Settings'
import * as serviceWorker from './serviceWorker'

const documentReady = () => {
  return new Promise((resolve) => {
    if (/complete|interactive/.test(document.readyState)) {
      resolve()
    } else {
      window.addEventListener(`DOMContentLoaded`, () => resolve())
    }
  })
}

const startup = async () => {
  let fetchSettings: Promise<Response>
  if (process.env.NODE_ENV === 'development') {
    fetchSettings = fetch(process.env.PUBLIC_URL + 'settings.dev.json')
  } else {
    fetchSettings = fetch(process.env.PUBLIC_URL + 'settings.json')
  }

  await documentReady()
  const resp = await fetchSettings
  const settings: Settings = await resp.json()

  store.dispatch(initializeApp(settings))

  ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={<CircularProgress />} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>,
    document.getElementById('root')
  )
}

// Todo: catch & log errors.
startup().catch((error) => console.log(error))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister()
serviceWorker.register({
  onSuccess: () => store.dispatch(serviceWorkerInitialized()),
  onUpdate: (reg) => store.dispatch(serviceWorkerUpdated(reg)),
})
