import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from './containers/Home'
import Main from './Main'
import AppLanding from './containers/AppLanding'
import DynamicRouteHandler from './containers/DynamicRouteHandler'

const AppRouter = () => {
  return (
    <Main>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/inspect" exact render={(props) => <AppLanding {...props} appFriendlyName="Inspect app" />} />
        <Route path="/time" exact render={(props) => <AppLanding {...props} appFriendlyName="Time app" />} />
        <DynamicRouteHandler />
      </Switch>
    </Main>
  )
}

export default AppRouter
