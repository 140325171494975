import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Settings } from '../../../models/Settings'

const initialState: Settings = {
  aiproxy_endpoint: '',
  authority: '',
  client_id: '',
  redirect_uri: '',
  silent_redirect_uri: '',
  response_type: '',
  scope: '',
  api_endpoint: '',
  app_version: '',
}

export const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    initializeApp: (_, action: PayloadAction<Settings>) => {
      return action.payload
    },
  },
})

export const { initializeApp } = slice.actions

export default slice.reducer
