import React from 'react'
import { Link } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import { Card, CardHeader, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  header: {
    position: `sticky`,
  },
  card: {
    width: '100%',
  },
  listItem: {
    padding: '0px',
    marginTop: '15px',
  },
}))

interface CardLinkProps {
  primary: string
  to: string
  onClick?: () => void
}

const CardLink = (props: CardLinkProps) => {
  const classes = useStyles()
  const { primary, to, onClick } = props

  let newTo = to
  if (to && !to.startsWith('/')) {
    newTo = `/${to}`
  }
  return (
    <ListItem className={classes.listItem} button component={Link} to={newTo} onClick={onClick}>
      <Card className={classes.card}>
        <CardHeader className={classes.header} title={primary} titleTypographyProps={{ variant: 'h6' }} />
      </Card>
    </ListItem>
  )
}

export default CardLink
