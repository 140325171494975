import { Box, Paper, Typography } from '@material-ui/core'
import React from 'react'
import CardLink from '../../components/CardLink'

const Home = () => {
  return (
    <>
      <Box>
        <Paper>
          <Box p={2}>
            <Typography variant="h5">Welcome to Agriware app docs!</Typography>
            <Box p={1} />
            <Typography>Please choose one of the apps below to view its documentation.</Typography>
          </Box>
        </Paper>
        <CardLink to="/inspect" primary="Inspect app" />
        <CardLink to="/time" primary="Time app" />
      </Box>
    </>
  )
}

export default Home
