import { StorageSettings } from '../models/StorageSettings'

export const createFileSASUri = (settings: StorageSettings, context: string, path: string) => {
  let storagePath = path
  if (storagePath.startsWith('.')) {
    storagePath = storagePath.substring(1, storagePath.length)
  }

  return `${settings.blobStorageUri}${settings.containerName}/${settings.contentFolder}/${context}${storagePath}${settings.sasToken}`
}
