import React, { FunctionComponent } from 'react'
import { makeStyles, Box, IconButton } from '@material-ui/core'
import { ArrowBack, ArrowForward } from '@material-ui/icons'
import { useHistory } from 'react-router'

const useStyles = makeStyles((theme) => ({
  iconButton: {
    width: 60,
    height: 50,
    paddingTop: 0,
  },
  icon: {
    color: theme.palette.grey[800],
    fontSize: 33,
  },
}))

interface NavigationProps {}

const Navigation: FunctionComponent<NavigationProps> = (props) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <>
      <Box display="flex" flexDirection="row">
        <IconButton className={classes.iconButton} onClick={() => history.goBack()}>
          <ArrowBack className={classes.icon} />
        </IconButton>
        <IconButton className={classes.iconButton} onClick={() => history.goForward()}>
          <ArrowForward className={classes.icon} />
        </IconButton>
      </Box>
      {props.children}
    </>
  )
}

export default Navigation
