import React, { FunctionComponent, useMemo } from 'react'
import { Link } from 'react-router-dom'

interface MarkdownRouterLinkProps {
  context: string
  href: string
  children: Array<any>
}

const createTo = (context: string, href: string) => {
  let newTo = href.replace('.md', '')

  if (newTo.startsWith('.')) {
    newTo = newTo.substring(1, newTo.length)
  }

  return `/${context}${newTo}`
}

const MarkdownRouterLink: FunctionComponent<MarkdownRouterLinkProps> = ({ href, children, context }) => {
  const newTo = useMemo(() => {
    return createTo(context, href)
  }, [context, href])

  return <>{href.startsWith('#') ? <a href={href}>{children && children[0]}</a> : <Link to={newTo}>{children && children[0] && children[0]}</Link>}</>
}

export default MarkdownRouterLink
