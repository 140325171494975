import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StorageSettings } from '../../../models/StorageSettings'

const initialState: StorageSettings = {
  blobStorageUri: '',
  containerName: '',
  sasToken: '',
  contentFolder: '',
}

export const slice = createSlice({
  name: 'storageSettings',
  initialState,
  reducers: {
    setStorageSettings(state, action: PayloadAction<StorageSettings>) {
      return action.payload
    },
  },
})

export const { setStorageSettings } = slice.actions

export default slice.reducer
