import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserData } from '../../../models/UserData'

const initialState: UserData = {
  name: ``,
  email: ``,
  tenantName: ``,
  accessToken: ``,
  roles: [],
}

export const slice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setUserData: (_, action: PayloadAction<UserData>) => {
      return action.payload
    },
  },
})

export const { setUserData } = slice.actions

export default slice.reducer
