import { Action, configureStore, ThunkAction, Middleware } from '@reduxjs/toolkit'
import { persistStore } from 'redux-persist'
import OidSyncMiddleware from '../services/auth/OidcSyncMiddleware'
import appReducer from './actions/app'
import userReducer from './actions/user'
import docsReducer from './actions/docs'
import { setUserData } from './actions/user/data'
import { selectApiEndpoint } from './selectors/app'
import { StorageSettings } from '../models/StorageSettings'
import { setStorageSettings } from './actions/app/storageSettings'
import thunk from 'redux-thunk'

const AzureBlobStorageMiddleware: Middleware = ({ dispatch, getState }) => (next) => (action) => {
  if (setUserData.match(action)) {
    const token = action.payload.accessToken
    const endpoint = selectApiEndpoint(getState())
    fetch(`${endpoint}/authenticate`, {
      method: 'POST',
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => {
        resp.json().then((json: StorageSettings) => {
          dispatch(setStorageSettings(json))
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return next(action)
}

export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    docs: docsReducer,
  },
  middleware: [thunk, OidSyncMiddleware, AzureBlobStorageMiddleware],
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store['getState']>
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>
