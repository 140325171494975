import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = { loggedIn: false, loginFailed: false }

export const slice = createSlice({
  name: 'loggedIn',
  initialState,
  reducers: {
    setUserLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload
    },
    setUserLoginFailed: (state, action: PayloadAction<boolean>) => {
      state.loginFailed = action.payload
    },
  },
})

export const { setUserLoggedIn, setUserLoginFailed } = slice.actions

export default slice.reducer
