import { Log, User, UserManager } from 'oidc-client'
import { Settings } from '../../models/Settings'

export class AuthService {
  public userManager: UserManager

  constructor(settings: Settings) {
    this.userManager = new UserManager({
      automaticSilentRenew: true,
      ...settings
    })

    // Todo: replace with a real logger.
    Log.logger = console
    Log.level = Log.INFO
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser()
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect({state: window.location.href })
  }

  public renewToken(): Promise<User> {
    return this.userManager.signinSilent()
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect()
  }
}
